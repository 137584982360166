<template>
  <div class="o-sound-listener">
    <div class="o-content">
      <div>
        <h1>Luister hier naar 'De Lach'</h1>
      </div>
      <div class="o-body">
        <div class="o-body__content">
          <AudioPlayer v-if="audio" :audio="audio" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioPlayer from './components/AudioPlayer/AudioPlayer.vue'
import { api } from '@dpgradio/creative'

export default {
  name: 'App',
  components: { AudioPlayer },
  data() {
    return {
      audio: null,
    }
  },
  async mounted() {
    this.audio = (await api.request().get(`actions/het-geluid/amount`)).audio
  },
}
</script>
