<template>
  <div>
    <audio
      ref="player"
      class="audio"
      preload="none"
      @ended="onEnd"
      @pause="onPause"
      @play="onPlay"
      @loadedmetadata="onLoadedMetadata"
    >
      <source :src="audio" type="audio/mpeg" />
    </audio>

    <div class="audio-player" :class="{ disabled }">
      <PlayButton :isPlaying="isPlaying" @toggleAudio="toggleAudio" />
      <div class="progress">
        <div class="progress-bar" :style="progressStyle" />
      </div>
    </div>
  </div>
</template>

<script>
import PlayButton from './PlayButton.vue'
import { dataLayer } from '@dpgradio/creative'

const SOUND_CODE = 'de-lach-2024'

export default {
  components: { PlayButton },
  props: ['audio', 'disabled'],
  data() {
    return {
      audioLoaded: false,
      isPlaying: false,
      totalDuration: 0,
    }
  },
  computed: {
    progressStyle() {
      return {
        animationDuration: `${this.totalDuration}s`,
        animationPlayState: this.isPlaying ? 'running' : 'paused',
      }
    },
  },
  methods: {
    onEnd() {
      this.isPlaying = false
      const count = localStorage.getItem(SOUND_CODE) ? parseInt(localStorage.getItem(SOUND_CODE)) + 1 : 1
      localStorage.setItem(SOUND_CODE, count)
      dataLayer.pushCampaignAction('SoundListenedCount', { count })
    },
    onPause() {
      this.isPlaying = false
    },
    onPlay() {
      this.isPlaying = true
    },
    onLoadedMetadata() {
      this.totalDuration = this.$refs.player.duration
    },
    toggleAudio() {
      if (this.$refs.player.paused) {
        dataLayer.pushCampaignAction('StartSound')
        this.$refs.player.play()
      } else {
        this.$refs.player.pause()
      }
    },
  },
}
</script>
