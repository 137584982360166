import { createApp } from 'vue'
import { configuration, dataLayer, privacy } from '@dpgradio/creative'

import './assets/css/index.scss'
import App from './App.vue'

const initialize = async () => {
  try {
    await configuration.retrieveConfigForStation('joe_fm')
  } catch (error) {
    alert(`
      Could not load configuration.
      - Have you set up a configuration for this application in Dario (see [updateConfigSchema.js])? If you do not want a custom configuration, remove the application name from the [configuration.retrieveConfigForDetectedStation] call in [main.js].
      - Did you provide a station name? (e.g. through the URL parameter 'stationId') If you want to set the station name in code, replace the configuration retrieval call with [configuration.retrieveConfigForStation('<station-name>', 'de-lach-sound')] in [main.js]. 

      ${error}
    `)
  }

  const app = createApp(App)
  app.mount('#sound-listener')

  // TODO: re-add privacy when the android fix is in place
  //privacy.initialize()

  dataLayer.initialize()
  dataLayer.pushVirtualPageView()

  dataLayer.setCampaignDetails({
    brand: configuration.stationId,
    signupRequired: false,
    title: 'De Lach',
    type: 'sound-listener',
  })
}

initialize()
